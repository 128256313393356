import { useQuery } from '@tanstack/react-query';
import { useSelectedStoreNumber } from '@cfacorp/ctrl-platform-shared-react-components';
import coreService from '../services/coreService';

export default function useGetLocationFromCore() {
  const selectedStoreNumber = useSelectedStoreNumber();

  const result = useQuery(
    ['getStoreDataFromCore', selectedStoreNumber],
    () => coreService.getStoreDataFromCore(selectedStoreNumber),
    {
      enabled: !!selectedStoreNumber.length,
    },
  );

  return result;
}
