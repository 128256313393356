import { z } from 'zod';

export const tier = z.object({
  value: z.number(),
  updatedAt: z.string().optional(),
  effectiveDate: z.string(),
  changeBy: z.string().optional(),
});

export const tiers = z.object({
  service: tier,
  environment: tier,
  order: tier,
});

export const caresTiersResponseSchema = z.object({
  locationNumber: z.string(),
  tiers: tiers,
  publishedTiers: tiers,
  lockedTiers: tiers,
});

type CaresTiers = z.infer<typeof caresTiersResponseSchema>;

export default CaresTiers;
