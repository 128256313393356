import { getAccessToken } from '@cfacorp/ctrl-platform-ui-core-utils';
import {
  DigitalOrderingService,
  GetDigitalOrderingSettingsRequest,
  GetDigitalOrderingSettingsResponse,
  UpdateDigitalOrderingSettingsRequest,
  UpdateDigitalOrderingSettingsResponse,
} from '@bufteam/cfacorp_digitalops.bufbuild_es/cfa/digitalops/digitalordering/v1/digitalordering_service_pb';
import { createClient, Interceptor } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import AppConstants from '../AppConstants';
import env from '../env';

const baseUrlMap: { [key: string]: string } = {
  [AppConstants.REACT_APP_ENVIRONMENT.DEV]:
    'https://dev.digitalops.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.TEST]:
    'https://digitalopsapi.test-spc.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.STAGE]:
    'https://digitalopsapi.stage-spc.cfadevelop.com',
  [AppConstants.REACT_APP_ENVIRONMENT.PROD]:
    'https://digitalopsapi.spc.cfahome.com',
};

const baseUrl = baseUrlMap[env.REACT_APP_ENVIRONMENT];

const headerInterceptor: Interceptor = next => async req => {
  req.header.set('Authorization', `Bearer ${getAccessToken()}`);
  return await next(req);
};

const transport = createConnectTransport({
  baseUrl,
  interceptors: [headerInterceptor],
});
const client = createClient(DigitalOrderingService, transport);

export const digitalOrderingService: {
  getDigitalOrderingSettings: (
    r: GetDigitalOrderingSettingsRequest,
  ) => Promise<GetDigitalOrderingSettingsResponse>;
  updateDigitalOrderingSettings: (
    r: UpdateDigitalOrderingSettingsRequest,
  ) => Promise<UpdateDigitalOrderingSettingsResponse>;
} = {
  getDigitalOrderingSettings: (r: GetDigitalOrderingSettingsRequest) =>
    client.getDigitalOrderingSettings(
      r,
    ) as Promise<GetDigitalOrderingSettingsResponse>,
  updateDigitalOrderingSettings: (r: UpdateDigitalOrderingSettingsRequest) =>
    client.updateDigitalOrderingSettings(
      r,
    ) as Promise<UpdateDigitalOrderingSettingsResponse>,
};
