import { useQuery } from '@tanstack/react-query';
import {
  useHasFeatureFlag,
  useIsFeatureFlagLoaded,
  useSelectedStoreNumber,
} from '@cfacorp/ctrl-platform-shared-react-components';
import { FeatureFlags } from '@cfacorp/ctrl-platform-ui-core-utils';
import dopService from '../services/dopService';

export default function useGetPersonalOrderSettingsForRoot() {
  const selectedStoreNumber = useSelectedStoreNumber();
  const allowPermissions = window.location.href.includes('digitalOrdering');
  const hasFeatureFlag = useHasFeatureFlag(FeatureFlags.DOP_PROTOBUF);
  const isFeatureFlagLoaded = useIsFeatureFlagLoaded();
  const result = useQuery(
    ['getPersonalOrderSettings', selectedStoreNumber, allowPermissions],
    () => dopService.getPersonalOrderSettings(selectedStoreNumber),
    {
      enabled:
        !!selectedStoreNumber.length &&
        allowPermissions &&
        !hasFeatureFlag &&
        isFeatureFlagLoaded,
    },
  );

  return result;
}
