/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */

export default {
  Amenities: {
    title: 'AMENITIES',
  },
  AmenitiesCardLabels: {
    catersBreakfast: 'Caters Breakfast',
    limitedMenu: 'Limited Menu',
    mobileOrdering: 'Mobile Ordering',
    mobilePayment: 'Mobile Payment',
    servesBreakfast: 'Serves Breakfast',
    servesLunchAtBreakfast: 'Serves Lunch at Breakfast',
    servesLunchChanges: 'Changes are reflected on the following business day',
    wifi: 'WiFi',
  },
  AppRoutes: {
    catering: 'Catering',
    closures: 'Closures',
    digitalOrdering: 'Digital Ordering',
    hours: 'Hours',
    mainMenu: 'Main Menu',
    onDemand: 'On Demand',
  },
  Cares: {
    acknowledge: 'Acknowledge',
    caption:
      'View and change the level of support CARES will provide customers on behalf of your restaurant',
    caresParticipationGuideLabel: 'You can review the CARES User Guide here',
    caresSupportTierLabel: 'CARES Support Tier',
    caresSupportTiersLabel: 'CARES Support Tiers',
    caresTierNoteLabel:
      'Please note: Tier changes go into effect every Monday at 3am EST',
    changeTierSelectionLabel: 'Change Tier Selection',
    header: 'CARES Support Tiers',
    liveTier: 'Live Tier: ',
    recover: 'Recover',
    simpleOrderContactLabel: 'Simple Order Contact',
    simpleServiceEnvironmentContactLabel:
      'Simple Service and Environment Contact',
  },
  CartButtons: {
    publishNow: 'Publish Now',
  },
  CateringSettings: {
    cateringRestrictions: 'All catering orders',
    delivery: 'Delivery',
    notifications: 'Notifications',
    orderDetails: 'All catering orders',
    pickUp: 'Pick-up',
  },
  CateringSettingsLabels: {
    add: 'Add',
    addEmailAddress: 'Notification Email Address(es)',
    addEmailContactButtonLabel: 'Add email contact',
    addEmailModalTitle: 'New Email contact',
    addPhoneContactButtonLabel: 'Add phone contact',
    addPhoneModalTitle: 'New phone contact',
    addPhoneNumber: 'Notification Phone Number(s)',
    available: 'Available',
    cateringDelivery: 'Catering Delivery',
    cateringMaxOrderLabel: 'Maximum order amount',
    cateringOrderMaxHelpText:
      'Applies to pickup and delivery orders placed for the following day or later. If an order amount is higher, the Customers will receive a message asking them to contact your Restaurant by phone.',
    cateringOrderMaxHelpText2: 'Recommendation for growth: $2,000+',
    cateringOrderMaxHelpText3: 'Minimum: $500',
    cateringPickup: 'Catering Pick-up',
    cateringPickupHelpText: 'Required sales channel',
    close: 'Close',
    closuresButtonText: 'Go to Scheduled Closures',
    closuresHelpText:
      'Schedule specific date/time ranges to make ordering online unavailable for Pick-up and Delivery.',
    closuresLabel: 'Limit or freeze online catering ordering hours',
    delete: 'Delete',
    deleteModalBody: 'Are you sure you want to delete this contact?',
    deleteModalTitle: 'Delete contact?',
    deliveryLeadTimeHelpText:
      'Minimum number of hours from when an order is placed to when it can be delivered. "Restaurant hours" refer to your Restaurant\'s open hours. "Clock hours" also include your Restaurant\'s non-open hours.',
    deliveryLeadTimeHelpText2:
      'Kitchens should have awareness of lead times and chicken thaw capacity to support lowered lead times.',
    deliveryLeadTimeHelpText3:
      'The following items have automatic required lead times:',
    deliveryLeadTimeHelpText3Li1: 'Chilled Nugget and Strip trays: 6 hours',
    deliveryLeadTimeHelpText3Li2: 'Mac & Cheese tray: 2 hours',
    deliveryLeadTimeHelpText3Li3: 'Sub tray: 5 hours.',
    deliveryLeadTimeHelpText4:
      'Recommendation for growth: 4 Restaurant hours or less',
    deliveryLeadTimeLabel: 'Delivery lead time',
    deliveryMaxDistanceHelpText:
      'The maximum distance your Restaurant is willing to drive a Catering Delivery order. The distance is measured directly to the destination; it is not measured by driving distance.',
    deliveryMaxDistanceHelpText2: 'Recommendation for growth: 10+ miles',
    deliveryMaxDistanceLabel: 'Maximum delivery distance (in miles)',
    deliveryMinAmountHelpText:
      'Minimum order amount to place a mobile order for Catering Delivery.',
    deliveryMinAmountHelpText2: 'Recommendation for growth: $150 or less',
    deliveryMinAmountLabel: 'Minimum order amount for Delivery',
    editButtonLabel: 'Edit',
    emailAddressLabel: 'Email Address',
    emailAddressPlaceholder: 'Jane@email.com',
    emailContactsLabel: 'Email Contacts',
    emailOwnerNamePlaceholder: 'Jane Smith',
    inStateDeliveryOnly: 'In-state delivery only',
    jumpToLabel: 'Jump to:',
    maxDeliveryOrderAllowedHelpText:
      'Limit the number of Catering Delivery orders per 15 minute time period.',
    maxDeliveryOrderAllowedLabel: 'Maximum Delivery orders',
    maxPickUpOrderAllowedHelpText:
      'Limit the number of Catering Pickup orders per 15 minute time period.',
    maxPickUpOrderAllowedLabel: 'Maximum Pick-up orders',
    nameLabel: 'Name',
    noEmailAddressLabel: 'No email contacts have been added',
    noPhoneNumberLabel: 'No phone contacts have been added',
    notificationPhoneNumberHelpText:
      'Must be a valid 10-digit phone number, dashes optional',
    notificationPhoneNumberLabel: 'Notification Phone Number',
    notificationPhoneProviderLabel: 'Mobile network',
    ownerNamePlaceholder: 'Jane Smith',
    phoneContactsLabel: 'Phone Contacts',
    phoneNumberInfoMessage:
      ' Please note that text message notifications may not be available for certain carriers, including Verizon. We recommend using email as your primary source for receiving notifications.',
    phoneNumberLabel: 'Phone Number',
    phoneNumberPlaceholder: '1234567890',
    pickupLeadTimeHelpText:
      'Minimum amount of hours from when an order is placed to when it can be picked up. "Restaurant hours" refer to your Restaurant\'s open hours. "Clock hours" also include your Restaurant\'s non-open hours.',
    pickupLeadTimeHelpText2:
      'Kitchens should have awareness of lead times and chicken thaw capacity to support lowered lead times.',
    pickupLeadTimeHelpText3:
      'The following items have automatic required lead times:',
    pickupLeadTimeHelpText3Li1: 'Chilled Nugget and Strip trays: 6 hours',
    pickupLeadTimeHelpText3Li2: 'Mac & Cheese tray: 2 hours',
    pickupLeadTimeHelpText3Li3: 'Sub tray: 5 hours.',
    pickupLeadTimeHelpText4: 'Recommendation for growth: 4 hours or less',
    pickupLeadTimeLabel: 'Pick-up lead time',
    providerPlaceholder: 'Select service provider',
    removeNotificationButtonLabel: 'Remove',
    restrictCateringOrdersHelpText:
      'Set a maximum number of orders per 15 minutes.',
    restrictCateringOrdersHelpText2:
      'This capacity is the same throughout open hours. If a time slot is full, the next time slot with availability will show.',
    restrictCateringOrdersLabel: 'Limit the number of catering orders',
    sameDayCateringOrderMaxHelpText:
      'For orders placed on the same day that the orders will be picked up or delivered.',
    sameDayCateringOrderMaxHelpText2: 'Example: 8am order for 12pm pickup.',
    sameDayCateringOrderMaxHelpText3: 'Recommendation for growth: $500+',
    sameDayCateringOrderMaxHelpText4: 'Minimum: $300',
    sameDayCateringOrderMaxLabel: 'Maximum Same-Day order amount',
    title: 'Catering',
    update: 'Update',
  },
  Closures: {
    addButtonLabel: 'Add',
    closureReasonPlaceholder: 'Closure Reason',
    createClosureButton: 'Schedule a store closure',
    createScheduledClosureModalTitle: 'Schedule a new Store Closure',
    dateRangeIndicator: '-',
    deleteModalCancelButton: 'Cancel',
    deleteModalConfirmButton: 'Delete',
    deleteModalSubtitle:
      'Are you sure you want to delete this store closure entry?',
    deleteModalTitle: 'Delete Store Closure?',
    descriptionLabel: 'Description',
    descriptionPlaceholder: 'Description',
    editClosureButton: 'Edit',
    editScheduledClosureModalTitle: 'Edit Store Closure',
    endDateError: 'End date must be after start date',
    endDateLabel: 'End Date',
    endDatePlaceholder: 'mm/dd/yyyy',
    openStoreButtonLabel: 'Open Store',
    reasonLabel: 'Reason for closure',
    reasonPlaceholder: 'Select',
    reasonText: 'Reason: {{reason}}',
    scheduledClosureModalCancelButton: 'Cancel',
    scheduledClosureModalCreateButton: 'Create',
    scheduledClosureModalDeleteButton: 'Delete',
    scheduledClosureModalUpdateButton: 'Update',
    startDateLabel: 'Start Date',
    startDatePlaceholder: 'mm/dd/yyyy',
    subheader:
      'Makes all mobile ordering (including Catering) unavailable for this restaurant',
    upcomingSectionEmptyText: 'No closures scheduled',
    upcomingSectionSubtitle: 'Schedule planned closures',
    upcomingSectionTitle: 'Store closures',
  },
  ContactSocialMedia: {
    email: 'Email Address',
    emailPlaceholder: 'Enter Email',
    facebookUrl: 'Facebook URL',
    instagramUrl: 'Instagram URL',
    title: 'Contact and Social Media',
    twitterUrl: 'Twitter URL',
    urlPlaceholder: 'Enter URL',
  },
  EmergencyActionModal: {
    cancelButtonText: 'Cancel',
    closeButtonText: 'Close now',
    closeModalBodyText:
      'This takes place immediately. This location will appear as closed on all online platforms.',
    closeStoreButtonText: 'Close store now',
    closeTitle: 'Close store now',
    dropdownLabel: 'Reason for closure',
    reOpenButtonText: 'Re-open now',
    reOpenModalBodyText:
      'This takes place immediately. This location will appear open on all online platforms.',
    reOpenStoreButtonText: 'Re-open store',
    reOpenTitle: 'Re-open store now?',
  },
  ErrorModal: {
    fileSizeErrorButton: 'Close',
    fileSizeErrorDescription:
      'The maximum image size is 500k (0.5MB). Please try again with a smaller image.',
    fileSizeErrorTitle: 'File size is too big',
  },
  ErrorPage: {
    buttonText: 'Go to Home Screen',
    errorDescription: 'Please try again or contact the helpdesk',
    errorTitle: 'Something went wrong',
  },
  HolidayHours: {
    cannotOverride: 'Cannot Override',
    cateringDelivery: 'Catering Delivery',
    close: 'Cancel',
    closed: 'Closed',
    editHours: 'Edit {{destination}} hours',
    notEditable: 'Not editable',
    publishNow: 'Publish Now',
    restaurantTitle: 'Restaurant (Dine-In, Carry-out, Catering Pick-up)',
    title: 'Holiday Hours',
    to: 'to',
  },
  Home: {
    subtitle:
      'Information entered here will be displayed on chick-fil-a.com, the Chick-fil-A app, and various other websites and 3rd party apps.',
    title: 'Digital Ordering',
  },
  Hours: {
    title: 'Hours & Closures',
  },
  LimitedCatering: {
    dateRangeIndicator: '-',
    deleteModalCancelButton: 'Cancel',
    deleteModalConfirmButton: 'Delete',
    deleteModalSubtitle:
      'Are you sure you want to delete this limited catering entry?',
    deleteModalTitle: 'Delete Limited Catering Hours?',
    deliveryBlockOrders: 'Block orders all day',
    deliveryCloseTimeLabel: 'Close time',
    deliveryOpenTimeLabel: 'Open time',
    deliverySelectTimeRange: 'Select a time range',
    deliveryTimeLabel: 'Delivery availability',
    deliveryTimeSeparator: 'to',
    descriptionLabel: 'Description',
    descriptionPlaceholder: 'Description',
    editLimitedCateringButton: 'Edit',
    endDateLabel: 'End Date',
    endDatePlaceholder: 'mm/dd/yyyy',
    header: 'Limited catering',
    modalCancelButton: 'Cancel',
    modalCreateButton: 'Create',
    modalDeleteButton: 'Delete',
    modalTitle: 'Schedule limited ordering',
    modalUpdateButton: 'Update',
    orLabel: 'or',
    pickupBlockOrders: 'Block orders all day',
    pickupCloseTimeLabel: 'Close time',
    pickupOpenTimeLabel: 'Open time',
    pickupSelectTimeRange: 'Select a time range',
    pickupTimeLabel: 'Pick-up availability',
    pickupTimeSeparator: 'to',
    reasonLabel: 'Reason',
    reasonPlaceholder: 'Select',
    reasonText: 'Reason: {{reason}}',
    restrictLabel: 'Restrict',
    scheduleButton: 'Schedule limited catering',
    sectionEmptyText: 'No catering freezes scheduled',
    sectionSubtitle:
      'Schedule specific date/time ranges to make ordering online unavailable for Pick-up and Delivery',
    sectionTimeSeparator: 'to',
    sectionTitle: 'Limit online catering orders',
    startDateLabel: 'Start Date',
    startDatePlaceholder: 'mm/dd/yyyy',
    subheader:
      'Schedule specific date/time ranges to make mobile ordering unavailable for Pick-up and Delivery',
  },
  NotFoundPage: {
    delivery: 'CFA Delivery',
    errorDescription: 'Error code 404',
    errorTitle: 'Page not found',
    help: 'Help',
    home: 'Home',
    kps: 'KPS',
    menu: 'Menu',
    ordering: 'Ordering',
    storeListing: 'Store Listing',
  },
  OrderingChannels: {
    additionalSettingsCardMaxAmountLabel: 'Maximum mobile order amount',
    additionalSettingsCardMaxAmountSubLabel:
      'Excludes Catering orders. This is the maximum amount your restaurant will accept for mobile orders. $75-$125 recommended.',
    additionalSettingsCardNotificationDelayLabel:
      '"Order Ready" notification delay (Bridge Time)',
    additionalSettingsCardNotificationDelaySubLabel:
      'The average time it takes from KPS bump to meal handoff for customers or delivery drivers. It affects the "Order Ready" notification for mobile customers. Order Ready Notification for 3rd Party, Estimated Wait Times for mobile customers (coming soon), and Auto-Assignment for Chick-fil-A Delivery (coming soon).',
    additionalSettingsSectionLabel: 'Order Settings',
    curbSidePauseCardSubtitle:
      'Curbside temporarily paused due to {{ reason }}',
    destinationsAtTheRestaurantRadioButtonLabel:
      'Release orders when customers arrive at the restaurant',
    destinationsCardAutoCheckInLabel:
      'Automatically release orders when customers are nearby (Admin-only)',
    destinationsCardAutoCheckInSublabel:
      'For customers who have location services enabled, orders will automatically fire as they approach the restaurant.',
    destinationsCardCfaDeliveryPauseLabel: 'Temporarily pause CFA Delivery',
    destinationsCardCfaDeliveryPauseSublabel:
      'Shows the Chick-fil-A Delivery service as unavailable to the customer for the current business day. Service will automatically resume at the start of the next business day. <br />Visit the <link></link> to suspend service for longer than a day.',
    destinationsCardCfaDeliveryUnPauseSublabel:
      'Shows the Chick-fil-A Delivery service as unavailable to the customer for the current business day. Service will automatically resume at the start of the next business day. <br />To reactivate long term suspension, please use the <link></link>.',
    destinationsCardCurbSidePauseLabel: 'Temporarily pause Curbside pick-up',
    destinationsCardCurbSidePauseSublabel:
      'Temporarily pauses Curbside pick-up for the current business day. Service will automatically resume at the start of the next business day. Please submit the <link></link> via Online HELP to suspend service for a longer duration of time.',
    destinationsCardEnabledLabel: 'Available',
    destinationsCardGeofenceSecondsLabel:
      'Distance away from restaurant (in minutes)',
    destinationsCardInstructionsEditButton: 'View and edit',
    destinationsCardInstructionsLabel: 'Mobile check-in instructions',
    destinationsCardInstructionsSublabel:
      'Instructions customers see when they arrive at the restaurant',
    destinationsCardMaximumCurbsideOrderDescriptionText:
      'Limit the number of Curbside orders per 10 minute time period',
    destinationsCardMaximumCurbsideOrderLabel: 'Maximum Curbside orders',
    destinationsCardMobileThruLabel: 'Mobile Thru (Admin only)',
    destinationsCardMobileThruSublabel:
      'Mobile Thru orders are picked up in the mobile-order only lane',
    destinationsCardNumberedParkingSpacesLabel: 'Numbered parking spaces',
    destinationsCardNumberedParkingSpacesSublabel:
      'Make sure your store clearly displays numbered Curbside signs before choosing this option',
    destinationsCardPauseButton: 'Pause',
    destinationsCardPausedLabel: 'Paused',
    destinationsCardPickupShelfLabel: 'Pick-up shelf for mobile orders',
    destinationsCardThirdPartyDeliveryArrivalLabel:
      'Notify KPS upon driver arrival',
    destinationsCardThirdPartyDeliveryArrivalSubLabel:
      'When turned on, a notification is sent to the KPS to inform Team Members that the 3rd Party Delivery driver has arrived to pick up the order.',
    destinationsCardThirdPartyDeliveryPauseLabel:
      'Temporarily pause 3rd Party Delivery',
    destinationsCardThirdPartyDeliveryPauseSublabel:
      'Temporarily pauses 3P Delivery, for all partners, through end of current business day. Service will automatically resume at the start of the next business day. Please submit the <link></link> via Online HELP to suspend service for a longer duration of time.',
    destinationsNearbyRadioButtonLabel:
      'Release orders when customers are nearby',

    destinationsRadioButtonHelpText:
      'For customers who have location services enabled in the CFA app',
    destinationsRadioButtonHiddenLabel:
      'Orders will release when customers arrive at the restaurant. This cannot be changed.',
    destinationsRadioButtonTitle:
      'Choose when to release {{ destinationType }} orders',
    destinationsSectionLabel: 'Order Type',
    destinationsSelectDistanceLabel: 'Select Driving distance (in minutes)',
    digitalOrderingLabel: 'Disable all Digital Ordering',
    digitalOrderingPlaceholder:
      'Makes all forms of online ordering unavailable for your restaurant',
    digitalOrderingTitle: 'Admin-only',
    driveThruDestinationsCardAutoCheckInLabel:
      'Automatically release orders when customers arrive at the restaurant (Admin-only)',
    editInstructionsModalCancelButton: 'Cancel',
    editInstructionsModalLineOneInputLabel: 'Line 1',
    editInstructionsModalLineOneInputPlaceholder: 'Line 1',
    editInstructionsModalLineTwoInputLabel: 'Line 2',
    editInstructionsModalLineTwoInputPlaceholder: 'Line 2',
    editInstructionsModalTitle: 'Mobile check-in instructions',
    editInstructionsModalUpdateButton: 'Update',
    editInstructionsModalUrlInputHelperText:
      'Provide the full URL (example: https://example.com/image.png)',
    editInstructionsModalUrlInputLabel: 'Image',
    editInstructionsModalUrlInputPlaceholder: 'Image URL',
    editInstructionsModalViewPreviewHideButton: 'Hide preview image',
    editInstructionsModalViewPreviewShowButton: 'Show preview image',
    hoursAndClosuresCardSubtitle:
      'Manage hours for all ordering channels, holidays and store closures',
    hoursAndClosuresCardTitle: 'Hours and Closures',
    hoursAndClosuresSectionLabel: 'Hours and Closures',
    pauseCardResumeButton: 'Resume now',
    pauseCardSubtitle: 'Will resume automatically on the next business day',
    pauseCardTitle: 'Paused',
    pauseDestinationModalCancelButton: 'Cancel',
    pauseDestinationModalExtremeWeatherRadioLabel:
      'Inclement weather (tornado, flooding, fire, etc.)',
    pauseDestinationModalLaborShortageRadioLabel: 'Significant labor shortage',
    pauseDestinationModalOtherRadioLabel: 'Other',
    pauseDestinationModalSubmitButton: 'Submit',
    pauseDestinationModalTitle:
      'Choose the reason for the pausing {{ destinationType }}',
    resetModalCancelButton: 'Cancel',
    resetModalConfirmButton: 'Reset to default',
    resetModalSubtitle:
      'Are you sure you want to reset the mobile check-in instructions for {{ destinationType }} back to its default? These defaults have been set by CFA Admin.',
    resetModalTitle: 'Reset mobile check-in instructions',
    resetToDefaultText: 'Reset to default',
    subtitle:
      'Information entered here will be displayed on chick-fil-a.com, the Chick-fil-A app, and various other websites and 3rd party apps.',
    title: 'Digital Ordering',
  },
  RegularHours: {
    available: 'Available',
    cateringDelivery: 'Catering Delivery',
    close: 'Cancel',
    closed: 'Closed',
    editHours: 'Edit {{destination}} hours',
    minimumHoursError:
      'Please select at least one hour of open time for 3rd party delivery hours. If you would like to deactivate third-party delivery, please contact <link></link>',
    open: 'Open',
    publishNow: 'Publish Now',
    restaurant: 'Restaurant (Dine-In, Carry-out, Catering Pick-up)',
    restaurantModalTitle: 'Edit Restaurant hours',
    title: 'Regular Hours',
    to: 'to',
    unavailable: 'Closed',
  },
  RestaurantDetails: {
    aboutUsLabel: 'Operator Bio',
    aboutUsPlaceholder:
      "Your name and role as operator are already displayed on the page so there's no need to reiterate. Use this copy to share more about yourself and your connection to the community-whether that's serving as an operator for a certain amount of years of how you're grateful to serve the greater area.",
    aboutUsTitle: 'About Us',
    careersBulletPointsAddBulletPoint: 'Add bullet point',
    careersBulletPointsDelete: 'Delete',
    careersBulletPointsHelperText: 'Maximum of 5',
    careersBulletPointsLabel: 'Bullet Points',
    careersBulletPointsPlaceholder: 'Bulleted list (e.g. team member benefits)',
    careersDescriptionLabel: 'Description',
    careersDescriptionText:
      "Working at a Chick-fil-A® restaurant is more than a job - it's an opportunity for teamwork and leadership development in a positive, people-focused environment. We hope you'll apply to join our team. Here are five great reasons to apply:",
    careersImagesButton: 'Upload image',
    careersImagesHelperText:
      '500k maximum file size. Accepts .jpg, .gif, and .png files.',
    careersImagesLabel: 'Career Image',
    careersImagesNoImages: 'No image currently uploaded',
    careersJobOpportunitiesHelperText:
      'Provide the full URL (http://example.com)',
    careersJobOpportunitiesLabel: 'Job opportunities link',
    careersJobOpportunitiesPlaceholder: 'http://example.com',
    careersJobOpportunitiesUrl: 'https:cfa.net',
    careersPlaceholder: '',
    careersTitle: 'Careers',
    careersVideosButton: 'Select video from library',
    careersVideosButtonVideoSelected: 'Change video',
    careersVideosHelperText: 'Select from a list of videos provided by CFA',
    careersVideosLabel: 'Career video',
    careersVideosNoVideo: 'No video selected',
    communityCareEventHelpText:
      'Host events and donate proceeds to schools or organizations',
    communityCareEventLabel: 'Community Care Event',
    communityDescriptionLabel: 'Description',
    communityDescriptionText:
      'At Chick-fil-A®, we love serving others - both in and outside our restaurants. From supporting local charities to hosting school spirit nights, Chick-fil-A® restaurants are committed to giving back in ways that are most meaningful to our customers and our communities.',
    communityDonationHelpText:
      'Support local groups or events with food or catering',
    communityDonationLabel: 'Community Donation',
    communityEmailRecipientLabel: 'Email for requests',
    communityImagesButton: 'Upload image',
    communityImagesHelperText:
      '500k maximum file size. Accepts .jpg, .gif, and .png files.',
    communityImagesLabel: 'Community Image',
    communityImagesNoImages: 'No image currently uploaded',
    communityModalVideosLabel: 'Select a community video',
    communityPlaceholder: '',
    communityRequestsHelperText:
      'Allows customers on chick-fil-a.com to contact you with donation requests for local groups and events',
    communityRequestsLabel: 'Accept community requests',
    communityTitle: 'Community',
    communityVideosButton: 'Select video from library',
    communityVideosHelperText: 'Select from a list of videos provided by CFA',
    communityVideosLabel: 'Community video',
    communityVideosNoVideo: 'No video selected',
    errorText: 'At least one checkbox is required to check',
    ImagesDeleteButton: 'Delete',
    title: 'RESTAURANT DETAILS',
  },
  RestaurantProfile: {
    subtitle:
      'Information entered here will be displayed on chick-fil-a.com, the Chick-fil-A app, and various other websites and 3rd party apps.',
    title: 'Store Listing',
  },
  RestaurantProfileLinks: {
    amenitiesSubtitle: 'coming soon',
    amenitiesTitle: 'Amenities Settings',
    contactSocialMediaSubtitle: 'coming soon',
    contactSocialMediaTitle: 'Contact and Social Media Settings',
    restaurantDetailsSubtitle: 'coming soon',
    restaurantDetailsTitle: 'Restaurant Details Settings',
  },
  VideoControl: {
    changeButton: 'Change Video',
    removeButton: 'Remove Video',
  },
  VideoModal: {
    cancelButton: 'Cancel',
    selectVideoButton: 'Select',
    videoSubLabel: 'Showing 1-{{totalLength}} of {{totalLength}} videos',
  },
};
