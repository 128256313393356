import {
  useHasFeatureFlag,
  useIsFeatureFlagLoaded,
  useSelectedStoreNumber,
} from '@cfacorp/ctrl-platform-shared-react-components';
import { create } from '@bufbuild/protobuf';
import { GetDigitalOrderingSettingsRequestSchema } from '@bufteam/cfacorp_digitalops.bufbuild_es/cfa/digitalops/digitalordering/v1/digitalordering_service_pb';
import { useQuery } from '@tanstack/react-query';
import { FeatureFlags } from '@cfacorp/ctrl-platform-ui-core-utils';
import { digitalOrderingService } from '../services/digitalOpsService';

export default function useGetDigitalOrderingSettingsForRoot() {
  const selectedStoreNumber = useSelectedStoreNumber();
  const allowPermissions = window.location.href.includes('digitalOrdering');
  const hasFeatureFlag = useHasFeatureFlag(FeatureFlags.DOP_PROTOBUF);
  const isFeatureFlagLoaded = useIsFeatureFlagLoaded();
  const result = useQuery(
    ['getDigitalOrderingSettingsForRoot', selectedStoreNumber],
    () =>
      digitalOrderingService.getDigitalOrderingSettings(
        create(GetDigitalOrderingSettingsRequestSchema, {
          storeId: selectedStoreNumber,
        }),
      ),
    {
      enabled:
        !!selectedStoreNumber.length &&
        allowPermissions &&
        hasFeatureFlag &&
        isFeatureFlagLoaded,
    },
  );

  return result;
}
