import {
  Destination,
  GetDigitalOrderingSettingsResponse,
} from '@bufteam/cfacorp_digitalops.bufbuild_es/cfa/digitalops/digitalordering/v1/digitalordering_service_pb';
import {
  PersonalOrderSettings,
  Destination as PersonalOrderSettingsDestination,
} from '../../../types/responses/PersonalOrderSettings';

export const mapPersonalOrderDestinationsToProtoBufDestinations = (
  destinations: PersonalOrderSettingsDestination[],
): {
  [key: string]: Destination;
} => {
  return destinations.reduce((prev, curr) => {
    const destination = { ...curr } as Destination;
    prev[curr.destinationType] = destination;
    return prev;
  }, {} as { [key: string]: Destination });
};

const mapPersonalOrderSettingsToDigitalOrderingSettings = (
  personalOrderSettings: PersonalOrderSettings,
  selectedStoreNumber: string,
): GetDigitalOrderingSettingsResponse => {
  const destinationsMap = mapPersonalOrderDestinationsToProtoBufDestinations(
    personalOrderSettings.destinations,
  );
  return {
    digitalOrderSettings: {
      ...personalOrderSettings,
      destinations: destinationsMap,
    },
    storeId: selectedStoreNumber,
  } as GetDigitalOrderingSettingsResponse;
};

export default mapPersonalOrderSettingsToDigitalOrderingSettings;
