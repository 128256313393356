import { z } from 'zod';
import { tiers } from './CaresTiers';

const legacyTiers = z.object({
  lockedTiers: tiers,
  tiers: tiers,
});

export const caresTiersResponseSchemaV2 = z.object({
  location: z.string(),
  pilot: z.boolean(),
  level: z.number(),
  name: z.string(),
  description: z.string(),
  legacyTiers: legacyTiers,
});

type CaresTiersV2 = z.infer<typeof caresTiersResponseSchemaV2>;

export default CaresTiersV2;
