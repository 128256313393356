import { useQuery } from '@tanstack/react-query';
import { useSelectedStoreNumber } from '@cfacorp/ctrl-platform-shared-react-components';
import dopService from '../services/dopService';

export default function useGetPersonalOrderSettingsForRoot() {
  const selectedStoreNumber = useSelectedStoreNumber();
  const allowPermissions = window.location.href.includes('digitalOrdering');
  const result = useQuery(
    ['getPersonalOrderSettings', selectedStoreNumber, allowPermissions],
    () => dopService.getPersonalOrderSettings(selectedStoreNumber),
    {
      enabled: !!selectedStoreNumber.length && allowPermissions,
    },
  );

  return result;
}
