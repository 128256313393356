import AppConstants from '../AppConstants';
import env from '../env';
import Store, { storeSchema } from '../types/responses/Store';
import createApiClient from './createApiClient';

const baseUrlMap: { [key: string]: string } = {
  [AppConstants.REACT_APP_ENVIRONMENT.DEV]: 'https://core.dev-ctrl.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.TEST]:
    'https://core.test-ctrl.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.STAGE]:
    'https://core.stage-ctrl.cfadevelop.com',
  [AppConstants.REACT_APP_ENVIRONMENT.PROD]: 'https://core.ctrl.cfahome.com',
};

const baseUrl = baseUrlMap[env.REACT_APP_ENVIRONMENT];

const apiClient = createApiClient(baseUrl);

const coreService = {
  getStoreDataFromCore: (storeNumber: string) =>
    apiClient
      .get(`stores/store/${storeNumber}`, {
        validationSchema: storeSchema,
      })
      .json<Store>(),
} as const;

export default coreService;
