import { lazy, Suspense, useEffect, useMemo } from 'react';
import './i18n';
import {
  appLoadingStatuses,
  AppNames,
  cartDataPointsGroups,
  deleteCartDataPoints,
  getCart,
  globalRoutes,
  platformBanner,
  platformToast,
  publishCartDataPoints,
  userPermissions,
} from '@cfacorp/ctrl-platform-ui-core-utils';
import { useQueryClient } from '@tanstack/react-query';
import RouteInfo from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/RouteInfo';
import { navigateToUrl } from 'single-spa';
import { useSelectedStoreNumber } from '@cfacorp/ctrl-platform-shared-react-components';
import RouteConstants from './routes/RouteConstants';
import useHasDopPerms from './hooks/useHasDopPerms';
import dopService from './services/dopService';
import { dopDataPointGroups } from './enums/DopDataPoints';
import useHasStoreListingPerms from './hooks/useHasStoreListingPerms';
import { storeListingDataPointGroups } from './enums/StoreListingDataPoints';
import useHasCaresPerms from './hooks/useHasCaresPerms';
import { caresDataPointGroups } from './enums/CaresDataPoints';
import CaresRouter from './routes/CaresRouter';
import useGetLocationFromCore from './queries/useGetLocationFromCore';
import useGetPersonalOrderSettingsForRoot from './queries/useGetPersonalOrderSettingsForRoot';

const DigitalOrderingRouter = lazy(
  () => import('./routes/DigitalOrderingRouter'),
);
const StoreListingRouter = lazy(() => import('./routes/StoreListingRouter'));

const ThirdPartyDeliveryRouter = lazy(
  () => import('./routes/ThirdPartyDeliveryRouter'),
);

export default function Root() {
  const selectedStoreNumber = useSelectedStoreNumber();
  const { hasDopPerms, permsLoaded } = useHasDopPerms();
  const { hasStoreListingPerms, storeListingPermsLoaded } =
    useHasStoreListingPerms();
  const { hasCaresPerms, caresPermsLoaded } = useHasCaresPerms();
  const queryClient = useQueryClient();
  const { data: coreLocation } = useGetLocationFromCore();
  const { data: personalOrderSettings } = useGetPersonalOrderSettingsForRoot();

  const dopRoutes: RouteInfo[] = useMemo(
    () => [
      {
        route: `${RouteConstants.DIGITAL_ORDERING_BASE_NAME}`,
        displayName: 'Digital Ordering',
        keywords: [
          'digital ordering',
          'ordering',
          'DOP',
          'app',
          'order type',
          'destination',
          'dining option',
          'pause',
          'auto release',
          'mobile check-in',
          'instruction',
          'max order',
          'order ready',
          'bridge',
          'notification',
          'clos',
          'emergency',
          'carry-out',
          'carryout',
          'cfa delivery',
          'operator lead',
          'curbside',
          'dine-in',
          'drive-thru',
          'mobile thru',
          '3rd party delivery',
          '3p',
          'pick-up',
        ],
      },
      {
        route: `${RouteConstants.DIGITAL_ORDERING_BASE_NAME}${RouteConstants.CATERING}`,
        displayName: 'Catering Settings',
        keywords: [
          'catering',
          'catering order',
          'limit',
          'same-day',
          'restrict',
          'lead time',
          'delivery',
          'state',
          'min',
          'max',
          'pick up',
        ],
      },
      {
        route: `${RouteConstants.DIGITAL_ORDERING_BASE_NAME}${RouteConstants.CATERING_NOTIFICATIONS}`,
        displayName: 'Catering Notification Contacts',
        keywords: [
          'catering',
          'notification',
          'contact',
          'phone',
          'call',
          'email',
        ],
      },
      {
        route: `${RouteConstants.DIGITAL_ORDERING_BASE_NAME}${RouteConstants.CATERING_LIMITED_SCHEDULE}`,
        displayName: 'Limited Catering Schedule',
        keywords: [
          'limit',
          'cater',
          'date',
          'schedule',
          'availab',
          'restrict',
          'block',
        ],
      },
      {
        route: `${RouteConstants.DIGITAL_ORDERING_BASE_NAME}${RouteConstants.HOURS}`,
        displayName: 'Regular Hours',
        keywords: [
          'regular',
          'hours',
          'restaurant',
          'store hours',
          'open',
          'close',
        ],
      },
      {
        route: `${RouteConstants.DIGITAL_ORDERING_BASE_NAME}${RouteConstants.HOLIDAY_HOURS}`,
        displayName: 'Holiday Hours',
        keywords: ['holiday', 'hours', 'restaurant', 'store', 'calendar'],
      },
      {
        route: `${RouteConstants.DIGITAL_ORDERING_BASE_NAME}${RouteConstants.CLOSURES}`,
        displayName: 'Scheduled Store Closures',
        keywords: [
          'schedule',
          'store',
          'closure',
          'plan',
          'restaurant',
          'clos',
          'calendar',
        ],
      },
    ],
    [],
  );

  const storeListingRoutes: RouteInfo[] = useMemo(
    () => [
      {
        route: `${RouteConstants.STORE_LISTING_BASE_NAME}`,
        displayName: 'Store Listing',
        keywords: [
          '.com',
          'store listing',
          'listing',
          'restaurant',
          'digital profile',
          'about',
          'community',
          'career',
          'job',
          'contact',
          'social media',
          'email',
          'facebook',
          'instagram',
          'twitter',
          'amenities',
          'payment',
          'wifi',
          'serves breakfast',
          'caters breakfast',
          'lunch at breakfast',
          'dop',
        ],
      },
    ],
    [],
  );

  const caresRoutes: RouteInfo[] = useMemo(
    () => [
      {
        route: `${RouteConstants.CARES_BASE_NAME}`,
        displayName: 'Cares',
        keywords: ['Cares', 'Support', 'Tiers'],
      },
    ],
    [],
  );

  const thirdPartyDeliveryRoutes: RouteInfo[] = useMemo(
    () => [
      {
        route: `${RouteConstants.THIRD_PARTY_DELIVERY_BASE_NAME}`,
        displayName: 'Third Party Delivery',
        keywords: [
          'digital ordering',
          'ordering',
          'DOP',
          'pause',
          'delivery',
          'third party',
          '3rd party delivery',
          '3p',
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    if (coreLocation?.tempClosed === 'True') {
      platformBanner.next([
        ...platformBanner?.getValue(),
        {
          bannerInfo: {
            severity: 'error',
            message: `Store Temporarily Closed Due to ${coreLocation?.tempClosedReason}.`,
            onClickMessage: hasDopPerms ? 'Re-open store' : '',
            type: 'storeClose',
            handleOnClickEvent: () => {
              navigateToUrl('/digitalOrdering/hours');
            },
          },
        },
      ]);
    }
  }, [coreLocation?.tempClosed, coreLocation?.tempClosedReason, hasDopPerms]);

  useEffect(() => {
    if (personalOrderSettings) {
      personalOrderSettings.destinations.forEach(d => {
        if (d.destinationType === '3rd Party Delivery' && d.tempDisabled) {
          platformBanner.next([
            ...platformBanner?.getValue(),
            {
              bannerInfo: {
                severity: 'info',
                message: `3rd Party Delivery is paused (${d.tempDisabledReason}) - Will resume automatically on the next business day`,
                type: d.destinationType,
                handleOnClickEvent: () => {
                  navigateToUrl('/digitalOrdering');
                },
              },
            },
          ]);
        }
        if (d.destinationType === 'Curb Side' && d.tempDisabled) {
          platformBanner.next([
            ...platformBanner?.getValue(),
            {
              bannerInfo: {
                severity: 'info',
                message: `Curbside is paused (${d.tempDisabledReason})`,
                type: d.destinationType,
                handleOnClickEvent: () => {
                  navigateToUrl('/digitalOrdering');
                },
              },
            },
          ]);
        }
        if (d.destinationType === 'CFA Delivery' && d.tempDisabled) {
          platformBanner.next([
            ...platformBanner?.getValue(),
            {
              bannerInfo: {
                severity: 'warning',
                message: `CFA Delivery is paused - Will resume automatically on the next business day`,
                type: d.destinationType,
                handleOnClickEvent: () => {
                  navigateToUrl('/digitalOrdering');
                },
              },
            },
          ]);
        }
      });
    }
  }, [personalOrderSettings]);

  useEffect(() => {
    if (permsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasDOPAccess: hasDopPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        dopLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...dopRoutes],
      });
    }
  }, [hasDopPerms, permsLoaded, dopRoutes]);

  useEffect(() => {
    if (permsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasDOPAccess: hasDopPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        dopLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...thirdPartyDeliveryRoutes],
      });
    }
  }, [hasDopPerms, permsLoaded, thirdPartyDeliveryRoutes]);

  useEffect(() => {
    if (storeListingPermsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasStoreListingAccess: hasStoreListingPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        storeListingLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...storeListingRoutes],
      });
    }
  }, [hasStoreListingPerms, storeListingPermsLoaded, storeListingRoutes]);

  useEffect(() => {
    if (caresPermsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasCARESAccess: hasCaresPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        caresLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...caresRoutes],
      });
    }
  }, [hasCaresPerms, caresPermsLoaded, caresRoutes]);

  useEffect(() => {
    cartDataPointsGroups.next({
      ...cartDataPointsGroups.value,
      [AppNames.DIGITAL_ORDERING]: dopDataPointGroups,
    });
  }, []);

  useEffect(() => {
    cartDataPointsGroups.next({
      ...cartDataPointsGroups.value,
      [AppNames.STORE_LISTING]: storeListingDataPointGroups,
    });
  }, []);

  useEffect(() => {
    cartDataPointsGroups.next({
      ...cartDataPointsGroups.value,
      [AppNames.CARES]: caresDataPointGroups,
    });
  }, []);

  useEffect(() => {
    publishCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.dataPoints.length &&
        cartGroups.appName === AppNames.DIGITAL_ORDERING &&
        selectedStoreNumber.length
      ) {
        dopService
          .publishChanges(selectedStoreNumber, cartGroups.dataPoints)
          .then(() => {
            publishCartDataPoints.next({
              dataPoints: [],
              appName: null,
            });
            platformToast.next({
              toastInfo: {
                content: 'Digital Ordering changes published',
                variant: 'success',
              },
            });
            getCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  useEffect(() => {
    publishCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.dataPoints.length &&
        cartGroups.appName === AppNames.STORE_LISTING &&
        selectedStoreNumber.length
      ) {
        dopService
          .publishChanges(selectedStoreNumber, cartGroups.dataPoints)
          .then(() => {
            publishCartDataPoints.next({
              dataPoints: [],
              appName: null,
            });
            platformToast.next({
              toastInfo: {
                content: 'Store Lusting changes published',
                variant: 'success',
              },
            });
            getCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  useEffect(() => {
    publishCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.dataPoints.length &&
        cartGroups.appName === AppNames.CARES &&
        selectedStoreNumber.length
      ) {
        dopService
          .publishChanges(selectedStoreNumber, cartGroups.dataPoints)
          .then(() => {
            publishCartDataPoints.next({
              dataPoints: [],
              appName: null,
            });
            platformToast.next({
              toastInfo: {
                content: 'Cares changes published',
                variant: 'success',
              },
            });
            getCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  useEffect(() => {
    deleteCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.dataPoints.length &&
        cartGroups.appName === AppNames.DIGITAL_ORDERING &&
        selectedStoreNumber.length
      ) {
        dopService
          .revertChanges(selectedStoreNumber, cartGroups.dataPoints)
          .then(() => {
            deleteCartDataPoints.next({
              dataPoints: [],
              appName: null,
            });
            getCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  useEffect(() => {
    deleteCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.dataPoints.length &&
        cartGroups.appName === AppNames.STORE_LISTING &&
        selectedStoreNumber.length
      ) {
        dopService
          .revertChanges(selectedStoreNumber, cartGroups.dataPoints)
          .then(() => {
            deleteCartDataPoints.next({
              dataPoints: [],
              appName: null,
            });
            getCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  useEffect(() => {
    deleteCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.dataPoints.length &&
        cartGroups.appName === AppNames.CARES &&
        selectedStoreNumber.length
      ) {
        dopService
          .revertChanges(selectedStoreNumber, cartGroups.dataPoints)
          .then(() => {
            deleteCartDataPoints.next({
              dataPoints: [],
              appName: null,
            });
            getCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  return (
    <Suspense fallback={null}>
      <DigitalOrderingRouter />
      <StoreListingRouter />
      <CaresRouter />
      <ThirdPartyDeliveryRouter />
    </Suspense>
  );
}
